import React, { useState, useEffect } from "react";
import "../../styles/main.scss";

import IndexPage from "../index";

const WeAreMotion = (props) => {
  return <IndexPage />;
};

export default WeAreMotion;
